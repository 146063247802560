import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft1 } from '@myua/icons';
import './PageBackLink.scss';
import { RoutePath } from '../router';

export const PageBackLink = () => {
  return (
    <Link to={RoutePath.root} className="lp-page-back-link">
      <ArrowLeft1 /> Back
    </Link>
  );
};
