import { Modal } from '@myua/kit';
import { OTPEnrollInner } from './OTPEnrollInner';

export const OTPEnroll: React.FC<{
  onSuccess: () => void;
  open: boolean;
  onClose: () => void;
}> = ({ onSuccess, open, onClose }) => {
  return (
    <Modal
      open={open}
      title="Two-Factor Authentication Setup"
      footer={null}
      destroyOnClose
      getContainer={'.lp-app__main'}
      onCancel={onClose}
      width={815}
      centered
    >
      <OTPEnrollInner onClose={onClose} onSuccess={onSuccess} open={open} />
    </Modal>
  );
};
