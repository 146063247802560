import React from 'react';
import './Page.scss';

export const Page: React.FC<
  React.PropsWithChildren<{ className?: string; bottom?: React.ReactElement }>
> = ({ className, children, bottom }) => {
  return (
    <div className={`lp-page ${className ?? ''}`}>
      <div className="lp-page__main">
        <div className="lp-page__content">{children}</div>
      </div>
      {bottom ? <div className="lp-page__bottom">{bottom}</div> : null}
    </div>
  );
};
