import React from 'react';
import { Logo } from './Logo';
import './PageWithPoster.scss';
export const PageWithPoster: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="lp-app">
      <div className="lp-app__poster">
        <Logo />
      </div>
      <div className="lp-app__main">{children}</div>
    </div>
  );
};
