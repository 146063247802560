export enum RoutePath {
  basename = '/login/',
  root = '/',
  recovery = '/recovery',
  newPassword = '/new-password',
  register = '/register',
}

export const REDIRECT_SEARCH_PARAM = 'redirect';
export const NEXT_SEARCH_PARAM = 'next';
