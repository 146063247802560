import React from 'react';
import './App.scss';

import { PageSignIn } from './PageSignIn';
import { PageRecovery } from './PageRecovery';
import { PageNewPassword } from './PageNewPassword';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RoutePath } from '../router';
import { PageWithPoster } from '../../../../general/modules/layout/components/PageWithPoster';

export const App = () => {
  return (
    <PageWithPoster>
      <Router basename={RoutePath.basename}>
        <Routes>
          <Route path={RoutePath.root} element={<PageSignIn />} />
          <Route path={RoutePath.recovery} element={<PageRecovery />} />
          <Route path={RoutePath.newPassword} element={<PageNewPassword />} />
        </Routes>
      </Router>
    </PageWithPoster>
  );
};
