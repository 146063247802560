import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import './OTPInput.scss';
import { Form, Input, InputRef } from '@myua/kit';
import classNames from 'classnames';

type digit = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0';
const digits = new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']);
const isDigit = (s: string): s is digit => digits.has(s);
const PLACEHOLDER = '#';

export const isValid = (value: string | undefined | null) => {
  if (value === undefined || value === null) {
    return false;
  }
  return !value.includes(PLACEHOLDER);
};
export const OTPValidator = (_: any, value: string) => {
  return isValid(value) ? Promise.resolve() : Promise.reject(new Error('Should enter OTP code'));
};

export const OTPInput: React.FC<{
  value?: string;
  length: number;
  onChange?: (otp: string | null) => void;
  focus?: boolean;
  onLastEnter?: (pasted?: boolean) => void;
  onBlur?: () => void;
  disabled?: boolean;
  _ref: React.ForwardedRef<{
    focus: () => void;
    focusOnDigit: (n: number) => void;
  }>;
}> = ({
  onChange,
  length,
  value = '',
  focus = false,
  onLastEnter,
  disabled = false,
  onBlur,
  _ref,
}) => {
  // Ref
  const containerRef = useRef<HTMLDivElement>(null);
  const firstInputRef = useRef<InputRef>(null);

  // Handle
  useImperativeHandle(_ref, () => ({
    focus: () => {
      // console.log('useImperativeHandle -> focus');
      doFocus();
      setCursor(0);
    },
    focusOnDigit: (n: number) => {
      doFocus();
      setCursor(n);
    },
  }));
  // Callbacks
  const doFocus = useCallback(() => {
    if (!firstInputRef.current) {
      console.debug(`firstInputRef.current is empty`);
      setTimeout(doFocus, 10);
    } else {
      firstInputRef.current.focus();
    }
  }, []);
  function getDigit<T>(index: number | null, defaultValue: T): T | digit {
    if (index === null) return defaultValue;
    const d = value[index];
    if (d === undefined) return defaultValue;
    if (d === PLACEHOLDER) return defaultValue;
    if (isDigit(d)) return d;
    return defaultValue;
  }
  function setDigit(position: number, v: digit | null) {
    const newCode = value.split('').slice(0, length);
    while (newCode.length < length) {
      newCode.push(PLACEHOLDER);
    }
    if (v === null) {
      newCode[position] = PLACEHOLDER;
    } else {
      newCode[position] = v;
    }
    onChange?.(newCode.join(''));
  }
  function applyCode(code: string) {
    if (code.length === length && code.split('').every(isDigit)) {
      onChange?.(code);
      onLastEnter?.();
    }
  }
  function onPaste(event: React.ClipboardEvent<HTMLInputElement>) {
    const cbd = event.clipboardData.getData('text');
    applyCode(cbd);
  }

  function setCursor(index: number | null) {
    if (index === null) {
      const inputs = containerRef.current!.querySelectorAll(`input`);
      inputs.forEach((input) => input.blur());
      onBlur?.();
      return;
    }
    const input: HTMLInputElement | null = containerRef.current!.querySelector(
      `input:nth-child(${index + 1})`,
    );
    if (input) {
      input.focus();
    }
  }
  function onKeyDown(key: string, index: number) {
    if (isDigit(key)) {
      setDigit(index, key);
      if (index < length - 1) {
        setCursor(index + 1);
      } else {
        onLastEnter?.();
        setCursor(null);
      }
    } else if (key === 'Backspace' && index > 0) {
      if (getDigit(index, false)) {
        setDigit(index, null);
      } else {
        setDigit(index - 1, null);
        setCursor(index - 1);
      }
    } else if (key === 'ArrowLeft' && index > 0) {
      setCursor(index - 1);
    } else if (key === 'ArrowRight' && index < length - 1) {
      setCursor(index + 1);
    } else {
      setDigit(index, getDigit(index, null));
    }
  }
  // fx
  useEffect(() => {
    if (focus) {
      doFocus();
    }
  }, [focus, doFocus]);

  const error = Form.Item.useStatus().status === 'error';

  return (
    <div className="otp-input" ref={containerRef} onBlur={() => setCursor(null)}>
      {Array(length)
        .fill(null)
        .map((_, i) => (
          <Input
            key={i}
            // autoComplete={i === 0 ? 'one-time-code' : undefined}
            name={i === 0 ? 'totp' : undefined}
            id={i === 0 ? 'totp' : undefined}
            ref={i === 0 ? firstInputRef : undefined}
            onKeyDown={(e) => onKeyDown(e.key, i)}
            onPaste={onPaste}
            tabIndex={0}
            inputMode="numeric"
            onChange={
              i === 0
                ? (e) => {
                    console.debug('xxx', e.target.value);
                    applyCode(e.target.value);
                  }
                : undefined
            }
            className={classNames('otp-input__input', {
              'otp-input__input-disabled': disabled,
              'otp-input__input-error': error,
            })}
            value={getDigit(i, '')}
          ></Input>
        ))}
    </div>
  );
};
