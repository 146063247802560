import { Progress } from '@myua/kit';
import { useEffect, useState } from 'react';
import { getCounter } from 'simple-totp/dist/hotp/getCounter';
import './OTPTimer.scss';

export const OTPTimer = () => {
  const [remainingMs, setRemainingMs] = useState(0);

  useEffect(() => {
    const t = setInterval(() => {
      const { remainingMs } = getCounter(Date.now(), 0, 30000);
      setRemainingMs(remainingMs);
    }, 100);

    return () => {
      clearInterval(t);
    };
  }, []);

  const percent = (remainingMs / 30000) * 100;
  const warn = remainingMs < 5000;

  return (
    <Progress
      className="otp-timer__progress"
      status={warn ? 'exception' : 'normal'}
      percent={percent}
      type="circle"
      strokeWidth={4}
      strokeLinecap="butt"
      width={100}
      format={() => (
        <div>
          <div className="otp-timer__number">{Math.ceil(remainingMs / 1000)}</div>
          <div className="otp-timer__sec">sec</div>
        </div>
      )}
    />
  );
};
