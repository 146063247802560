import { Button, Checkbox, Divider, Form, Input, message } from '@myua/kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as api from '../../../api';
import { Provider } from '../../../api';
import { OTPEnroll } from '../../otp/OTPEnroll';
import { OTPInput, OTPValidator } from '../../otp/OTPInput';
import { NEXT_SEARCH_PARAM, REDIRECT_SEARCH_PARAM, RoutePath } from '../router';
import { Page } from './Page';
import './PageSignIn.scss';

enum FieldKey {
  username = 'username',
  code = 'code',
  password = 'password',
  remember = 'remember',
}

export type SigninFormValues = {
  [FieldKey.username]: string;
  [FieldKey.password]: string;
  [FieldKey.remember]: boolean;
  [FieldKey.code]?: string;
};

const getRedirectLink = () => {
  const { searchParams } = new URL(window.location.href);
  return (
    searchParams.get(REDIRECT_SEARCH_PARAM) ??
    searchParams.get(NEXT_SEARCH_PARAM) ??
    window.location.origin
  );
};

const addRedirectToLink = (link: string) => {
  const url = new URL(link);
  const redirectLink = getRedirectLink();
  url.searchParams.set('next', redirectLink);
  return url.href;
};

const ERROR_SEARCH_PARAM_KEY = 'error';
const ERROR_MESSAGE_KEY_404 = 'error404';
const ERROR_MESSAGE_KEY_403 = 'error403';

const errors: Record<string, { show: () => void; destroy: () => void }> = {
  '404': {
    show: () =>
      void message.error({
        duration: 0,
        key: ERROR_MESSAGE_KEY_404,
        content:
          'The user with this email does not exist. Please try another email or contact the system administrator.',
      }),
    destroy: () => message.destroy(ERROR_MESSAGE_KEY_404),
  },
  '403': {
    show: () =>
      void message.error({
        duration: 0,
        key: ERROR_MESSAGE_KEY_403,
        content: 'Please contact support@adsadvisor.io to restore your access.',
      }),
    destroy: () => message.destroy(ERROR_MESSAGE_KEY_403),
  },
};

export const PageSignIn = () => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [providersRequested, setProvidersRequested] = useState(false);
  const [OTPRequired, setOTPRequired] = useState(false);
  const [OTPEnrollOpen, setOTPEnrollOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const abortRef = useRef(new AbortController());
  const otpInputRef = useRef<{ focus: () => void; focusOnDigit: (n: number) => void }>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    function focus() {
      if (otpInputRef.current) {
        otpInputRef.current.focus();
      } else {
        setTimeout(focus, 10);
      }
    }

    if (OTPRequired) {
      focus();
    }
  }, [OTPRequired]);

  useEffect(() => {
    const error = searchParams.get(ERROR_SEARCH_PARAM_KEY);
    if (error && error in errors) {
      searchParams.delete(ERROR_SEARCH_PARAM_KEY);
      setSearchParams(searchParams);
      errors[error].show();
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    return () => {
      for (const key in errors) {
        errors[key].destroy();
      }
    };
  }, []);

  // Разово загружаем провайдеры
  useEffect(() => {
    if (providersRequested) {
      return;
    }
    setProvidersRequested(true);
    void (async () => {
      const loadProvidersResult = await api.loadProviders();
      if (loadProvidersResult.success) {
        setProviders(loadProvidersResult.data);
      }
    })();
  }, [setProviders, providersRequested, setProvidersRequested]);

  const providerButtons: { title: string; url: string }[] = [];
  providers.forEach((provider) => {
    providerButtons.push({
      title: `Sign in with ${provider.title}`,
      url: addRedirectToLink(provider.url),
    });
  });

  useEffect(() => {
    const { current } = abortRef;
    return () => {
      // При unmount'е страницы обрываем запрос
      current.abort();
    };
  }, [abortRef]);

  const [form] = Form.useForm();
  const finalize = useCallback(() => {
    window.location.href = getRedirectLink();
  }, []);

  return (
    <Page className="lp-sign-in" bottom={<Link to={RoutePath.recovery}>Forgot password?</Link>}>
      <OTPEnroll
        onSuccess={finalize}
        open={OTPEnrollOpen}
        onClose={() => setOTPEnrollOpen(false)}
      />
      <h1>Sign in to AdsAdvisor</h1>

      <div className="lp-provider-buttons">
        {providerButtons.map((button) => (
          <Button key={button.url} href={button.url}>
            {button.title}
          </Button>
        ))}
      </div>

      {providerButtons.length ? (
        <Divider plain className="lp-sign-in__divider">
          or
        </Divider>
      ) : null}

      <Form
        layout="vertical"
        form={form}
        validateMessages={{ required: 'Required field' }}
        onFinish={(values: SigninFormValues) => {
          void (async () => {
            setInProgress(true);

            const result = await api.login(values, abortRef.current.signal);
            setInProgress(false);

            if (abortRef.current.signal.aborted) {
              return;
            }

            if (result.success) {
              if (values.code === undefined) {
                //login was successful without code -> starting enroll
                setOTPEnrollOpen(true);
              } else {
                finalize();
              }
              return;
            }

            if (
              (result.status === 403 || result.status === 401) &&
              (result.data.detail as string).includes('2FA')
            ) {
              void message.warning('You are required to provide your one-time password');
              setOTPRequired(true);
              return;
            }

            if ('code' in result.data && Array.isArray(result.data.code)) {
              void message.error({ content: result.data.code.join(',') });
              otpInputRef.current?.focusOnDigit(5);
              return;
            }

            const errors = Object.values(result.data).flatMap((items: string[]) => items);
            if (errors.length === 0) {
              errors.push('Login process failed');
            }
            errors.forEach((error) => void message.error({ content: error }));
          })();
        }}
      >
        <Form.Item
          label="Email"
          name={FieldKey.username}
          required={false}
          rules={[
            {
              type: 'email',
              message: 'Not valid email',
              transform: (value: SigninFormValues[FieldKey.username] | undefined) => value?.trim(),
            },
            { required: true },
          ]}
        >
          <Input
            placeholder="example@gmail.com"
            onBlur={() => {
              const value: SigninFormValues[FieldKey.username] | undefined = form.getFieldValue(
                FieldKey.username,
              );
              const fixed = value?.trim();
              if (value !== fixed) {
                form.setFieldsValue({ [FieldKey.username]: fixed });
              }
            }}
            onChange={() => errors['404'].destroy()}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name={FieldKey.password}
          required={false}
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        {OTPRequired && (
          <Form.Item
            tooltip={{
              trigger: 'hover',
              title:
                'If you lost the access to your authentication app or have some troubles with it please contact our system administrator',
            }}
            label={`One-time password`}
            validateTrigger={['onBlur']}
            name={FieldKey.code}
            required={false}
            rules={[
              {
                validator: OTPValidator,
              },
            ]}
          >
            <OTPInput
              length={6}
              focus
              _ref={otpInputRef}
              //
              onLastEnter={() => setTimeout(form.submit, 0)}
            />
          </Form.Item>
        )}
        <Form.Item
          name={FieldKey.remember}
          valuePropName="checked"
          initialValue={true}
          className="lp-sign-in__remember"
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="lp-sign-in__submit"
          loading={inProgress}
        >
          Log in to AdsAdvisor
        </Button>
      </Form>
    </Page>
  );
};
