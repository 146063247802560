import { SigninFormValues } from './modules/app/components/PageSignIn';
import { request } from '../request';

export type Provider = { provider: string; url: string; title: string };
export const loadProviders = () => request<Provider[]>({ url: '/api/core/oauth/providers/' });

export const login = (data: SigninFormValues, signal?: AbortSignal) =>
  request({ url: '/api/core/oauth/login/', method: 'post', data, signal });

export const OTPEnable = (signal?: AbortSignal) =>
  request<{ qrcode: string }>({ url: '/api/core/oauth/otp-enable/', method: 'post', signal });

export const OTPConfirm = (code: string, signal?: AbortSignal) =>
  request({ url: '/api/core/oauth/otp-enable/', method: 'patch', signal, data: { code } });

export const requestRecoveryLink = (email: string) =>
  request({ url: '/api/core/oauth/restore-password/', method: 'post', data: { email } });

export const changePassword = (data: { password: string; email: string; token: string }) =>
  request({ url: '/api/core/oauth/restore-password/', method: 'put', data });
