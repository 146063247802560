import axios, { Axios } from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.headers.common = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
};

export const request = <T = any>(...args: Parameters<Axios['request']>) =>
  axios
    .request<T>(...args)
    .then(({ data, status }) => ({ success: true as const, status, data }))
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        const raw: unknown = error.response?.data;
        const data: Record<string, any> = typeof raw === 'object' && raw !== null ? raw : {};
        return { success: false as const, status: error.response?.status ?? null, data };
      } else {
        return { success: false as const, status: null, data: {} };
      }
    });
