import React, { useState } from 'react';
import { Button, Form, Input, message } from '@myua/kit';
import { Page } from './Page';
import './PageRecovery.scss';
import * as api from '../../../api';
import { PageBackLink } from './PageBackLink';

enum FieldKey {
  email = 'email',
}

type FormValues = {
  [FieldKey.email]: string;
};

export const PageRecovery = () => {
  const [inProgress, setInProgress] = useState(false);

  const [form] = Form.useForm();

  return (
    <Page className="lp-recovery" bottom={<PageBackLink />}>
      <h1>Recovery password</h1>
      <div className="lp-recovery__hint">
        Enter the email associated with your account, we will send a recovery link
      </div>
      <Form
        layout="vertical"
        form={form}
        validateMessages={{ required: 'Required field' }}
        onFinish={(values: FormValues) => {
          void (async () => {
            const { email } = values;

            setInProgress(true);
            const result = await api.requestRecoveryLink(email);
            setInProgress(false);

            if (result.success) {
              void message.success('Recovery link has been sent');
            } else {
              const errors = Object.values(result.data).flatMap((items: string[]) => items);
              if (errors.length === 0) {
                errors.push('Recovery link has not been sent');
              }
              errors.forEach((error) => void message.error(error));
            }
          })();
        }}
      >
        <Form.Item
          label="Enter your Email"
          name={FieldKey.email}
          required={false}
          rules={[
            {
              type: 'email',
              message: 'Not valid email',
              transform: (value: FormValues[FieldKey.email] | undefined) => value?.trim(),
            },
            { required: true },
          ]}
        >
          <Input
            placeholder="example@gmail.com"
            onBlur={() => {
              const value: FormValues[FieldKey.email] | undefined = form.getFieldValue(
                FieldKey.email,
              );
              const fixed = value?.trim();
              if (value !== fixed) {
                form.setFieldsValue({ [FieldKey.email]: fixed });
              }
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="lp-recovery__submit"
          loading={inProgress}
        >
          Send a recovery link
        </Button>
      </Form>
    </Page>
  );
};
