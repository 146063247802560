import React, { useState } from 'react';
import { Button, Form, Input, message } from '@myua/kit';
import { Page } from './Page';
import './PageNewPassword.scss';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../router';
import * as api from '../../../api';
import { PageBackLink } from './PageBackLink';

enum FieldKey {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

type FormValues = {
  [FieldKey.password]: string;
  [FieldKey.confirmPassword]: string;
};

export const PageNewPassword = () => {
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  return (
    <Page className="lp-new-password" bottom={<PageBackLink />}>
      <h1>Recovery password</h1>
      <Form
        layout="vertical"
        form={form}
        validateMessages={{ required: 'Required field' }}
        onFinish={(values: FormValues) => {
          void (async () => {
            const { password } = values;

            const url = new URL(window.location.href);
            const email = url.searchParams.get('email') ?? '';
            const token = url.searchParams.get('token') ?? '';

            setInProgress(true);
            const result = await api.changePassword({ password, email, token });
            setInProgress(false);

            if (result.success) {
              void message.success('Password has been changed');
              navigate(RoutePath.root);
            } else {
              const errors = Object.values(result.data).flatMap((items: string[]) => items);
              if (errors.length === 0) {
                errors.push('Password has not been changed');
              }
              errors.forEach((error) => void message.error(error));
            }
          })();
        }}
      >
        <Form.Item
          label="New password"
          name={FieldKey.password}
          required={false}
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm password"
          name={FieldKey.confirmPassword}
          required={false}
          rules={[
            { required: true },
            {
              validator: (rule, value) => {
                if (!value || form.getFieldValue(FieldKey.password) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The passwords do not match'));
              },
            },
          ]}
          dependencies={[FieldKey.password]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="lp-new-password__submit"
          loading={inProgress}
        >
          Save password
        </Button>
      </Form>
    </Page>
  );
};
