import React from 'react';
import './Logo.scss';

export const Logo = () => {
  return (
    <div className="lp-logo">
      <svg
        width="300"
        height="78"
        viewBox="0 0 300 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 13.877H8.3254V72.1547H0V13.877Z" fill="white" />
        <path d="M16.6509 5.55078H24.9763V72.154H16.6509V5.55078Z" fill="white" />
        <path d="M33.3018 13.877H41.6272V72.1547H33.3018V13.877Z" fill="white" />
        <path
          d="M79.8047 16.5877L72.0518 46.2122H64.3979L75.6681 10.1476H80.4487L79.8047 16.5877ZM85.9228 46.2122L78.1203 16.5877L77.402 10.1476H82.2569L93.6261 46.2122H85.9228ZM86.1952 32.7623V38.7813H69.9216V32.7623H86.1952Z"
          fill="white"
        />
        <path
          d="M109.702 40.2922V8.16602H116.662V46.2122L110.37 46.237L109.702 40.2922ZM95.8059 33.7531V31.9696C95.8059 29.7899 95.9875 27.8827 96.3508 26.2479C96.7306 24.6131 97.292 23.2507 98.0351 22.1609C98.7782 21.071 99.6864 20.2619 100.76 19.7334C101.85 19.1885 103.096 18.916 104.5 18.916C105.772 18.916 106.886 19.238 107.844 19.8821C108.818 20.5096 109.644 21.393 110.321 22.5324C110.998 23.6718 111.543 25.0176 111.956 26.5699C112.368 28.1221 112.657 29.8229 112.823 31.6724V34.2484C112.624 35.9988 112.319 37.6336 111.906 39.1528C111.51 40.672 110.965 41.9931 110.271 43.116C109.594 44.2389 108.777 45.1223 107.819 45.7663C106.861 46.3938 105.747 46.7076 104.475 46.7076C103.072 46.7076 101.825 46.4269 100.735 45.8654C99.6617 45.304 98.7534 44.4783 98.0104 43.3885C97.2838 42.2821 96.7306 40.928 96.3508 39.3262C95.9875 37.7079 95.8059 35.8502 95.8059 33.7531ZM102.741 31.9696V33.7531C102.741 35.008 102.799 36.0897 102.915 36.9979C103.047 37.8896 103.253 38.6244 103.534 39.2024C103.831 39.7638 104.219 40.1849 104.698 40.4656C105.177 40.7298 105.772 40.8619 106.482 40.8619C107.406 40.8619 108.158 40.6308 108.736 40.1684C109.314 39.6895 109.743 39.0372 110.024 38.2116C110.321 37.3694 110.486 36.4034 110.519 35.3135V30.6321C110.486 29.7239 110.379 28.9065 110.197 28.1799C110.015 27.4533 109.759 26.8341 109.429 26.3222C109.099 25.8103 108.686 25.4139 108.191 25.1332C107.712 24.8525 107.15 24.7121 106.506 24.7121C105.813 24.7121 105.218 24.8525 104.723 25.1332C104.244 25.4139 103.864 25.8515 103.584 26.446C103.303 27.024 103.088 27.7753 102.94 28.7C102.807 29.6083 102.741 30.6981 102.741 31.9696Z"
          fill="white"
        />
        <path
          d="M133.778 38.9794C133.778 38.5171 133.621 38.1043 133.307 37.741C132.993 37.3777 132.498 37.0061 131.821 36.6263C131.144 36.23 130.244 35.7924 129.121 35.3135C127.899 34.8181 126.817 34.3062 125.876 33.7778C124.935 33.2494 124.134 32.6632 123.474 32.0192C122.83 31.3752 122.342 30.6403 122.012 29.8147C121.682 28.989 121.517 28.0478 121.517 26.9909C121.517 25.8515 121.723 24.803 122.136 23.8452C122.565 22.8709 123.176 22.0205 123.969 21.2939C124.762 20.5508 125.728 19.9729 126.867 19.5601C128.023 19.1307 129.319 18.916 130.756 18.916C132.77 18.916 134.496 19.2711 135.933 19.9811C137.386 20.6747 138.5 21.649 139.277 22.904C140.053 24.1589 140.441 25.6204 140.441 27.2882H133.505C133.505 26.6937 133.414 26.1653 133.233 25.7029C133.051 25.224 132.754 24.8525 132.341 24.5883C131.945 24.3241 131.408 24.192 130.731 24.192C130.236 24.192 129.79 24.2993 129.393 24.514C129.014 24.7286 128.708 25.0176 128.477 25.3809C128.262 25.7277 128.155 26.1405 128.155 26.6194C128.155 26.9827 128.229 27.3047 128.378 27.5854C128.543 27.8661 128.799 28.1386 129.146 28.4028C129.493 28.667 129.955 28.9477 130.533 29.245C131.127 29.5257 131.854 29.8395 132.713 30.1862C134.496 30.8468 135.957 31.5568 137.097 32.3164C138.253 33.076 139.111 33.9677 139.673 34.9915C140.234 35.9988 140.515 37.2456 140.515 38.7318C140.515 39.9207 140.284 41.0023 139.822 41.9766C139.376 42.9508 138.723 43.793 137.865 44.5031C137.006 45.2131 135.974 45.7581 134.769 46.1379C133.58 46.5177 132.25 46.7076 130.781 46.7076C128.617 46.7076 126.793 46.2782 125.306 45.4196C123.82 44.5609 122.697 43.4628 121.938 42.1252C121.178 40.7711 120.798 39.3675 120.798 37.9144H127.387C127.42 38.8061 127.585 39.5079 127.883 40.0198C128.196 40.5152 128.617 40.8619 129.146 41.0601C129.674 41.2583 130.26 41.3573 130.904 41.3573C131.548 41.3573 132.077 41.2748 132.49 41.1096C132.919 40.928 133.241 40.6555 133.456 40.2922C133.67 39.9124 133.778 39.4748 133.778 38.9794Z"
          fill="white"
        />
        <path
          d="M158.324 16.5877L150.572 46.2122H142.918L154.188 10.1476H158.968L158.324 16.5877ZM164.443 46.2122L156.64 16.5877L155.922 10.1476H160.777L172.146 46.2122H164.443ZM164.715 32.7623V38.7813H148.441V32.7623H164.715Z"
          fill="white"
        />
        <path
          d="M188.221 40.2922V8.16602H195.182V46.2122L188.89 46.237L188.221 40.2922ZM174.326 33.7531V31.9696C174.326 29.7899 174.507 27.8827 174.871 26.2479C175.25 24.6131 175.812 23.2507 176.555 22.1609C177.298 21.071 178.206 20.2619 179.28 19.7334C180.369 19.1885 181.616 18.916 183.02 18.916C184.291 18.916 185.406 19.238 186.364 19.8821C187.338 20.5096 188.164 21.393 188.841 22.5324C189.518 23.6718 190.063 25.0176 190.475 26.5699C190.888 28.1221 191.177 29.8229 191.342 31.6724V34.2484C191.144 35.9988 190.839 37.6336 190.426 39.1528C190.03 40.672 189.485 41.9931 188.791 43.116C188.114 44.2389 187.297 45.1223 186.339 45.7663C185.381 46.3938 184.267 46.7076 182.995 46.7076C181.591 46.7076 180.345 46.4269 179.255 45.8654C178.181 45.304 177.273 44.4783 176.53 43.3885C175.804 42.2821 175.25 40.928 174.871 39.3262C174.507 37.7079 174.326 35.8502 174.326 33.7531ZM181.261 31.9696V33.7531C181.261 35.008 181.319 36.0897 181.435 36.9979C181.567 37.8896 181.773 38.6244 182.054 39.2024C182.351 39.7638 182.739 40.1849 183.218 40.4656C183.697 40.7298 184.291 40.8619 185.001 40.8619C185.926 40.8619 186.677 40.6308 187.255 40.1684C187.833 39.6895 188.263 39.0372 188.543 38.2116C188.841 37.3694 189.006 36.4034 189.039 35.3135V30.6321C189.006 29.7239 188.898 28.9065 188.717 28.1799C188.535 27.4533 188.279 26.8341 187.949 26.3222C187.619 25.8103 187.206 25.4139 186.71 25.1332C186.232 24.8525 185.67 24.7121 185.026 24.7121C184.333 24.7121 183.738 24.8525 183.243 25.1332C182.764 25.4139 182.384 25.8515 182.103 26.446C181.823 27.024 181.608 27.7753 181.459 28.7C181.327 29.6083 181.261 30.6981 181.261 31.9696Z"
          fill="white"
        />
        <path
          d="M208.31 40.8619L212.619 19.4114H219.852L212.496 46.2122H208.161L208.31 40.8619ZM205.758 19.4114L210.143 40.8867L210.217 46.2122H205.857L198.501 19.4114H205.758Z"
          fill="white"
        />
        <path
          d="M230.478 19.4114V46.2122H223.518V19.4114H230.478ZM223.196 12.4759C223.196 11.4026 223.535 10.5191 224.212 9.82558C224.905 9.11552 225.83 8.76049 226.986 8.76049C228.158 8.76049 229.083 9.11552 229.76 9.82558C230.437 10.5191 230.776 11.4026 230.776 12.4759C230.776 13.5328 230.429 14.4162 229.735 15.1263C229.058 15.8198 228.15 16.1666 227.011 16.1666C225.838 16.1666 224.905 15.8198 224.212 15.1263C223.535 14.4162 223.196 13.5328 223.196 12.4759Z"
          fill="white"
        />
        <path
          d="M247.867 38.9794C247.867 38.5171 247.71 38.1043 247.396 37.741C247.082 37.3777 246.587 37.0061 245.91 36.6263C245.233 36.23 244.333 35.7924 243.21 35.3135C241.988 34.8181 240.906 34.3062 239.965 33.7778C239.024 33.2494 238.223 32.6632 237.563 32.0192C236.919 31.3752 236.431 30.6403 236.101 29.8147C235.771 28.989 235.606 28.0478 235.606 26.9909C235.606 25.8515 235.812 24.803 236.225 23.8452C236.654 22.8709 237.265 22.0205 238.058 21.2939C238.851 20.5508 239.817 19.9729 240.956 19.5601C242.112 19.1307 243.408 18.916 244.845 18.916C246.859 18.916 248.585 19.2711 250.022 19.9811C251.475 20.6747 252.589 21.649 253.366 22.904C254.142 24.1589 254.53 25.6204 254.53 27.2882H247.594C247.594 26.6937 247.503 26.1653 247.322 25.7029C247.14 25.224 246.843 24.8525 246.43 24.5883C246.034 24.3241 245.497 24.192 244.82 24.192C244.325 24.192 243.879 24.2993 243.482 24.514C243.103 24.7286 242.797 25.0176 242.566 25.3809C242.351 25.7277 242.244 26.1405 242.244 26.6194C242.244 26.9827 242.318 27.3047 242.467 27.5854C242.632 27.8661 242.888 28.1386 243.235 28.4028C243.582 28.667 244.044 28.9477 244.622 29.245C245.216 29.5257 245.943 29.8395 246.802 30.1862C248.585 30.8468 250.046 31.5568 251.186 32.3164C252.342 33.076 253.2 33.9677 253.762 34.9915C254.323 35.9988 254.604 37.2456 254.604 38.7318C254.604 39.9207 254.373 41.0023 253.91 41.9766C253.465 42.9508 252.812 43.793 251.954 44.5031C251.095 45.2131 250.063 45.7581 248.857 46.1379C247.669 46.5177 246.339 46.7076 244.87 46.7076C242.706 46.7076 240.882 46.2782 239.395 45.4196C237.909 44.5609 236.786 43.4628 236.027 42.1252C235.267 40.7711 234.887 39.3675 234.887 37.9144H241.476C241.509 38.8061 241.674 39.5079 241.972 40.0198C242.285 40.5152 242.706 40.8619 243.235 41.0601C243.763 41.2583 244.349 41.3573 244.993 41.3573C245.637 41.3573 246.166 41.2748 246.579 41.1096C247.008 40.928 247.33 40.6555 247.545 40.2922C247.759 39.9124 247.867 39.4748 247.867 38.9794Z"
          fill="white"
        />
        <path
          d="M258.22 33.7283V31.9201C258.22 29.8229 258.468 27.9652 258.963 26.3469C259.475 24.7286 260.202 23.3746 261.143 22.2847C262.084 21.1783 263.216 20.3444 264.537 19.783C265.874 19.205 267.369 18.916 269.02 18.916C270.688 18.916 272.182 19.205 273.503 19.783C274.841 20.3444 275.98 21.1783 276.921 22.2847C277.879 23.3746 278.614 24.7286 279.126 26.3469C279.638 27.9652 279.894 29.8229 279.894 31.9201V33.7283C279.894 35.8089 279.638 37.6584 279.126 39.2767C278.614 40.895 277.879 42.2573 276.921 43.3637C275.98 44.4701 274.849 45.304 273.528 45.8654C272.207 46.4269 270.721 46.7076 269.069 46.7076C267.418 46.7076 265.924 46.4269 264.586 45.8654C263.265 45.304 262.126 44.4701 261.168 43.3637C260.21 42.2573 259.475 40.895 258.963 39.2767C258.468 37.6584 258.22 35.8089 258.22 33.7283ZM265.156 31.9201V33.7283C265.156 34.9668 265.238 36.0401 265.404 36.9483C265.585 37.8566 265.841 38.6079 266.171 39.2024C266.518 39.7968 266.931 40.2344 267.41 40.5152C267.889 40.7959 268.442 40.9363 269.069 40.9363C269.697 40.9363 270.258 40.7959 270.754 40.5152C271.249 40.2344 271.654 39.7968 271.968 39.2024C272.298 38.6079 272.537 37.8566 272.686 36.9483C272.851 36.0401 272.934 34.9668 272.934 33.7283V31.9201C272.934 30.7146 272.843 29.6578 272.661 28.7496C272.479 27.8249 272.223 27.0735 271.893 26.4956C271.563 25.9011 271.15 25.4552 270.655 25.158C270.176 24.8442 269.631 24.6874 269.02 24.6874C268.409 24.6874 267.864 24.8442 267.385 25.158C266.923 25.4552 266.518 25.9011 266.171 26.4956C265.841 27.0735 265.585 27.8249 265.404 28.7496C265.238 29.6578 265.156 30.7146 265.156 31.9201Z"
          fill="white"
        />
        <path
          d="M291.214 25.3809V46.2122H284.278V19.4114H290.817L291.214 25.3809ZM297.53 19.2133L297.431 26.124C297.117 26.058 296.737 26.0084 296.291 25.9754C295.862 25.9424 295.482 25.9258 295.152 25.9258C294.376 25.9258 293.715 26.0497 293.17 26.2974C292.625 26.5286 292.171 26.8836 291.808 27.3625C291.461 27.8249 291.197 28.4028 291.015 29.0964C290.834 29.7899 290.726 30.5743 290.693 31.4495L289.381 30.9541C289.381 29.2037 289.513 27.6019 289.777 26.1488C290.058 24.6791 290.47 23.4076 291.015 22.3343C291.56 21.2444 292.213 20.4022 292.972 19.8077C293.748 19.2133 294.624 18.916 295.598 18.916C295.928 18.916 296.275 18.9491 296.638 19.0151C297.018 19.0647 297.315 19.1307 297.53 19.2133Z"
          fill="white"
        />
        <path
          d="M297.556 68.5633C297.556 69.3672 297.362 70.0397 296.976 70.5808C296.605 71.1219 296.072 71.5315 295.376 71.8098C294.68 72.0881 293.845 72.2272 292.871 72.2272C292.052 72.2272 291.341 72.1576 290.738 72.0185C290.151 71.8794 289.633 71.6861 289.184 71.4388V69.5836C289.664 69.831 290.236 70.0629 290.9 70.2793C291.565 70.4803 292.238 70.5808 292.918 70.5808C293.861 70.5808 294.541 70.4184 294.958 70.0938C295.391 69.7537 295.608 69.3054 295.608 68.7488C295.608 68.4396 295.523 68.1614 295.353 67.914C295.198 67.6667 294.912 67.4193 294.495 67.1719C294.093 66.9246 293.505 66.6463 292.732 66.3371C291.99 66.0279 291.349 65.7188 290.808 65.4096C290.282 65.1004 289.872 64.7293 289.579 64.2965C289.3 63.8636 289.161 63.3071 289.161 62.6269C289.161 61.5756 289.563 60.764 290.367 60.192C291.186 59.62 292.253 59.334 293.567 59.334C294.278 59.334 294.943 59.4113 295.561 59.5659C296.18 59.705 296.767 59.906 297.324 60.1688L296.651 61.792C296.327 61.6374 295.987 61.506 295.631 61.3978C295.275 61.2742 294.912 61.1814 294.541 61.1196C294.185 61.0423 293.822 61.0036 293.451 61.0036C292.694 61.0036 292.114 61.1427 291.712 61.421C291.31 61.6838 291.109 62.0471 291.109 62.5109C291.109 62.851 291.202 63.1447 291.387 63.3921C291.573 63.6395 291.874 63.8791 292.292 64.111C292.725 64.3274 293.312 64.5825 294.054 64.8762C294.796 65.1699 295.43 65.4714 295.956 65.7806C296.481 66.0898 296.875 66.4685 297.138 66.9169C297.416 67.3497 297.556 67.8985 297.556 68.5633Z"
          fill="white"
        />
        <path
          d="M280.419 59.334C281.811 59.334 282.862 59.6973 283.573 60.4239C284.3 61.135 284.663 62.2945 284.663 63.9023V71.9953H282.646V64.0414C282.646 63.0365 282.437 62.2867 282.019 61.792C281.602 61.2973 280.96 61.05 280.095 61.05C278.827 61.05 277.946 61.4365 277.451 62.2095C276.972 62.9824 276.732 64.0955 276.732 65.5487V71.9953H274.715V59.5659H276.338L276.64 61.2587H276.755C277.003 60.8258 277.32 60.4703 277.706 60.192C278.093 59.8983 278.518 59.6818 278.982 59.5427C279.445 59.4036 279.925 59.334 280.419 59.334Z"
          fill="white"
        />
        <path
          d="M270.093 65.7574C270.093 66.7932 269.962 67.713 269.699 68.5169C269.451 69.3054 269.088 69.9778 268.609 70.5344C268.13 71.0909 267.55 71.5161 266.87 71.8098C266.19 72.0881 265.432 72.2272 264.597 72.2272C263.824 72.2272 263.105 72.0881 262.441 71.8098C261.791 71.5161 261.219 71.0909 260.725 70.5344C260.245 69.9778 259.874 69.3054 259.612 68.5169C259.349 67.713 259.217 66.7932 259.217 65.7574C259.217 64.3815 259.434 63.222 259.867 62.279C260.315 61.3205 260.949 60.5939 261.768 60.0992C262.587 59.5891 263.554 59.334 264.667 59.334C265.733 59.334 266.669 59.5891 267.473 60.0992C268.292 60.5939 268.934 61.3205 269.397 62.279C269.861 63.222 270.093 64.3815 270.093 65.7574ZM261.304 65.7574C261.304 66.7314 261.42 67.5816 261.652 68.3082C261.884 69.0194 262.247 69.5682 262.742 69.9547C263.237 70.3411 263.871 70.5344 264.644 70.5344C265.417 70.5344 266.05 70.3411 266.545 69.9547C267.04 69.5682 267.403 69.0194 267.635 68.3082C267.882 67.5816 268.006 66.7314 268.006 65.7574C268.006 64.768 267.882 63.9255 267.635 63.2298C267.403 62.5341 267.04 62.0007 266.545 61.6297C266.05 61.2432 265.409 61.05 264.62 61.05C263.461 61.05 262.618 61.4674 262.093 62.3022C261.567 63.137 261.304 64.2888 261.304 65.7574Z"
          fill="white"
        />
        <path
          d="M254.577 59.5692V71.9987H252.559V59.5692H254.577ZM253.58 54.9082C253.889 54.9082 254.159 55.0164 254.391 55.2329C254.623 55.4338 254.739 55.7585 254.739 56.2068C254.739 56.6397 254.623 56.9643 254.391 57.1807C254.159 57.3972 253.889 57.5054 253.58 57.5054C253.255 57.5054 252.977 57.3972 252.745 57.1807C252.513 56.9643 252.397 56.6397 252.397 56.2068C252.397 55.7585 252.513 55.4338 252.745 55.2329C252.977 55.0164 253.255 54.9082 253.58 54.9082Z"
          fill="white"
        />
        <path
          d="M246.931 70.5608C247.224 70.5608 247.526 70.5376 247.835 70.4912C248.144 70.4294 248.399 70.3675 248.6 70.3057V71.8594C248.384 71.9676 248.075 72.0526 247.673 72.1145C247.286 72.1918 246.915 72.2304 246.56 72.2304C245.941 72.2304 245.369 72.1222 244.844 71.9058C244.318 71.6739 243.893 71.2796 243.568 70.7231C243.259 70.1666 243.104 69.3859 243.104 68.381V61.1459H241.458V60.172L243.151 59.3604L243.916 56.7168H245.122V59.5691H248.508V61.1459H245.122V68.3346C245.122 69.0921 245.292 69.6564 245.632 70.0274C245.972 70.383 246.405 70.5608 246.931 70.5608Z"
          fill="white"
        />
        <path
          d="M237.632 59.5684V71.9978H236.008L235.707 70.3514H235.614C235.367 70.7842 235.042 71.1398 234.64 71.4181C234.254 71.6963 233.828 71.8973 233.365 72.021C232.901 72.1601 232.414 72.2297 231.904 72.2297C230.976 72.2297 230.196 72.0751 229.562 71.7659C228.928 71.4413 228.449 70.9466 228.124 70.2818C227.815 69.617 227.66 68.759 227.66 67.7078V59.5684H229.701V67.5687C229.701 68.5581 229.91 69.3001 230.327 69.7948C230.744 70.2895 231.386 70.5369 232.252 70.5369C233.102 70.5369 233.767 70.3668 234.246 70.0267C234.741 69.6711 235.088 69.161 235.289 68.4962C235.506 67.816 235.614 66.9966 235.614 66.0382V59.5684H237.632Z"
          fill="white"
        />
        <path d="M222.486 71.9949H220.468V54.3711H222.486V71.9949Z" fill="white" />
        <path
          d="M215.847 65.7574C215.847 66.7932 215.716 67.713 215.453 68.5169C215.206 69.3054 214.843 69.9778 214.363 70.5344C213.884 71.0909 213.304 71.5161 212.624 71.8098C211.944 72.0881 211.186 72.2272 210.352 72.2272C209.579 72.2272 208.86 72.0881 208.195 71.8098C207.546 71.5161 206.974 71.0909 206.479 70.5344C206 69.9778 205.629 69.3054 205.366 68.5169C205.103 67.713 204.972 66.7932 204.972 65.7574C204.972 64.3815 205.188 63.222 205.621 62.279C206.069 61.3205 206.703 60.5939 207.523 60.0992C208.342 59.5891 209.308 59.334 210.421 59.334C211.488 59.334 212.423 59.5891 213.227 60.0992C214.046 60.5939 214.688 61.3205 215.152 62.279C215.616 63.222 215.847 64.3815 215.847 65.7574ZM207.059 65.7574C207.059 66.7314 207.175 67.5816 207.407 68.3082C207.638 69.0194 208.002 69.5682 208.496 69.9547C208.991 70.3411 209.625 70.5344 210.398 70.5344C211.171 70.5344 211.805 70.3411 212.299 69.9547C212.794 69.5682 213.157 69.0194 213.389 68.3082C213.637 67.5816 213.76 66.7314 213.76 65.7574C213.76 64.768 213.637 63.9255 213.389 63.2298C213.157 62.5341 212.794 62.0007 212.299 61.6297C211.805 61.2432 211.163 61.05 210.375 61.05C209.215 61.05 208.373 61.4674 207.847 62.3022C207.322 63.137 207.059 64.2888 207.059 65.7574Z"
          fill="white"
        />
        <path
          d="M201.155 67.5649C201.155 68.5544 200.923 69.3969 200.46 70.0926C200.011 70.7728 199.37 71.2984 198.535 71.6694C197.716 72.0405 196.734 72.226 195.59 72.226C195.018 72.226 194.461 72.1951 193.92 72.1332C193.395 72.0714 192.908 71.9864 192.459 71.8781C192.026 71.7545 191.64 71.6076 191.3 71.4376V69.4433C191.841 69.6906 192.498 69.9148 193.271 70.1158C194.059 70.3167 194.863 70.4172 195.683 70.4172C196.44 70.4172 197.066 70.309 197.561 70.0926C198.071 69.8761 198.45 69.567 198.697 69.165C198.96 68.7631 199.091 68.2915 199.091 67.7505C199.091 67.2094 198.975 66.7533 198.744 66.3823C198.527 66.0113 198.156 65.6712 197.63 65.362C197.105 65.0373 196.386 64.6972 195.474 64.3416C194.825 64.0788 194.253 63.8006 193.758 63.5068C193.263 63.1976 192.846 62.8498 192.506 62.4633C192.166 62.0768 191.91 61.6362 191.74 61.1415C191.57 60.6468 191.485 60.0748 191.485 59.4255C191.485 58.5443 191.694 57.7945 192.111 57.1762C192.544 56.5423 193.132 56.0554 193.874 55.7152C194.631 55.3751 195.505 55.2051 196.494 55.2051C197.344 55.2051 198.125 55.2901 198.836 55.4602C199.547 55.6302 200.204 55.8544 200.807 56.1326L200.158 57.9182C199.602 57.6709 199.006 57.4622 198.373 57.2921C197.754 57.1221 197.113 57.037 196.448 57.037C195.814 57.037 195.281 57.1375 194.848 57.3385C194.43 57.5395 194.113 57.8255 193.897 58.1965C193.681 58.5521 193.572 58.9695 193.572 59.4487C193.572 60.0053 193.681 60.469 193.897 60.8401C194.113 61.2111 194.469 61.5435 194.964 61.8372C195.458 62.1309 196.115 62.4479 196.935 62.788C197.847 63.1435 198.612 63.53 199.231 63.9474C199.864 64.3494 200.344 64.8363 200.668 65.4083C200.993 65.9803 201.155 66.6992 201.155 67.5649Z"
          fill="white"
        />
        <path
          d="M173.808 59.334C174.581 59.334 175.269 59.4886 175.872 59.7978C176.49 60.107 177.016 60.5785 177.449 61.2123H177.565L177.82 59.5659H179.443V72.204C179.443 73.3789 179.25 74.3606 178.863 75.1491C178.492 75.9529 177.913 76.5559 177.124 76.9578C176.351 77.3598 175.362 77.5607 174.156 77.5607C173.306 77.5607 172.533 77.4912 171.837 77.352C171.141 77.2284 170.508 77.0351 169.936 76.7723V74.894C170.322 75.0949 170.74 75.265 171.188 75.4041C171.652 75.5587 172.139 75.6747 172.649 75.752C173.174 75.8293 173.715 75.8679 174.272 75.8679C175.261 75.8679 176.034 75.551 176.591 74.9172C177.163 74.2988 177.449 73.4485 177.449 72.3663V71.8794C177.449 71.6939 177.457 71.431 177.472 71.0909C177.488 70.7354 177.503 70.488 177.519 70.3489H177.426C177.024 70.9827 176.521 71.4542 175.918 71.7634C175.316 72.0726 174.62 72.2272 173.831 72.2272C172.332 72.2272 171.157 71.6629 170.307 70.5344C169.456 69.4058 169.031 67.829 169.031 65.8038C169.031 64.4743 169.217 63.3303 169.588 62.3718C169.974 61.3978 170.523 60.648 171.234 60.1224C171.961 59.5968 172.819 59.334 173.808 59.334ZM174.133 61.05C173.499 61.05 172.95 61.2355 172.486 61.6065C172.038 61.9776 171.698 62.5186 171.466 63.2298C171.234 63.9409 171.118 64.8066 171.118 65.827C171.118 67.3575 171.373 68.5324 171.884 69.3517C172.409 70.1556 173.174 70.5576 174.179 70.5576C174.751 70.5576 175.246 70.4803 175.663 70.3257C176.081 70.1556 176.421 69.9006 176.684 69.5604C176.947 69.2049 177.14 68.7565 177.263 68.2155C177.403 67.6744 177.472 67.0328 177.472 66.2908V65.8038C177.472 64.6752 177.348 63.7631 177.101 63.0675C176.869 62.3718 176.506 61.8616 176.011 61.537C175.532 61.2123 174.906 61.05 174.133 61.05Z"
          fill="white"
        />
        <path
          d="M160.22 59.334C161.612 59.334 162.663 59.6973 163.374 60.4239C164.101 61.135 164.464 62.2945 164.464 63.9023V71.9953H162.446V64.0414C162.446 63.0365 162.238 62.2867 161.82 61.792C161.403 61.2973 160.761 61.05 159.896 61.05C158.628 61.05 157.747 61.4365 157.252 62.2095C156.773 62.9824 156.533 64.0955 156.533 65.5487V71.9953H154.516V59.5659H156.139L156.44 61.2587H156.556C156.804 60.8258 157.121 60.4703 157.507 60.192C157.894 59.8983 158.319 59.6818 158.782 59.5427C159.246 59.4036 159.725 59.334 160.22 59.334Z"
          fill="white"
        />
        <path
          d="M149.227 59.5692V71.9987H147.209V59.5692H149.227ZM148.23 54.9082C148.539 54.9082 148.809 55.0164 149.041 55.2329C149.273 55.4338 149.389 55.7585 149.389 56.2068C149.389 56.6397 149.273 56.9643 149.041 57.1807C148.809 57.3972 148.539 57.5054 148.23 57.5054C147.905 57.5054 147.627 57.3972 147.395 57.1807C147.163 56.9643 147.047 56.6397 147.047 56.2068C147.047 55.7585 147.163 55.4338 147.395 55.2329C147.627 55.0164 147.905 54.9082 148.23 54.9082Z"
          fill="white"
        />
        <path
          d="M141.581 70.5608C141.874 70.5608 142.176 70.5376 142.485 70.4912C142.794 70.4294 143.049 70.3675 143.25 70.3057V71.8594C143.034 71.9676 142.725 72.0526 142.323 72.1145C141.936 72.1918 141.565 72.2304 141.21 72.2304C140.591 72.2304 140.019 72.1222 139.494 71.9058C138.968 71.6739 138.543 71.2796 138.218 70.7231C137.909 70.1666 137.754 69.3859 137.754 68.381V61.1459H136.108V60.172L137.801 59.3604L138.566 56.7168H139.772V59.5691H143.157V61.1459H139.772V68.3346C139.772 69.0921 139.942 69.6564 140.282 70.0274C140.622 70.383 141.055 70.5608 141.581 70.5608Z"
          fill="white"
        />
        <path
          d="M128.148 59.334C129.153 59.334 130.019 59.5659 130.745 60.0297C131.472 60.4934 132.028 61.1505 132.415 62.0007C132.817 62.8356 133.018 63.8172 133.018 64.9458V66.1748H125.041C125.072 67.5816 125.404 68.6561 126.038 69.3981C126.672 70.1247 127.561 70.488 128.705 70.488C129.447 70.488 130.104 70.4184 130.676 70.2793C131.263 70.1247 131.866 69.9083 132.484 69.63V71.4156C131.881 71.6939 131.286 71.8948 130.699 72.0185C130.127 72.1576 129.431 72.2272 128.612 72.2272C127.499 72.2272 126.517 71.9876 125.667 71.5083C124.816 71.0291 124.152 70.318 123.672 69.3749C123.193 68.4164 122.954 67.2492 122.954 65.8734C122.954 64.5129 123.17 63.3457 123.603 62.3718C124.036 61.3978 124.639 60.648 125.412 60.1224C126.2 59.5968 127.112 59.334 128.148 59.334ZM128.148 61.0036C127.251 61.0036 126.54 61.3205 126.015 61.9544C125.489 62.5727 125.18 63.4385 125.087 64.5516H130.931C130.931 63.8404 130.83 63.222 130.629 62.6964C130.428 62.1708 130.119 61.7611 129.702 61.4674C129.3 61.1582 128.782 61.0036 128.148 61.0036Z"
          fill="white"
        />
        <path
          d="M112.199 63.5772C112.199 63.8246 112.183 64.1492 112.152 64.5512C112.137 64.9531 112.122 65.301 112.106 65.5947H112.199C112.292 65.471 112.423 65.2932 112.593 65.0613C112.763 64.8294 112.941 64.5976 113.126 64.3657C113.312 64.1183 113.474 63.9173 113.613 63.7627L117.301 59.5655H119.643L114.912 64.8758L119.967 71.9949H117.556L113.544 66.1976L112.199 67.4266V71.9949H110.205V54.3711H112.199V63.5772Z"
          fill="white"
        />
        <path
          d="M105.064 59.334C105.28 59.334 105.512 59.3494 105.759 59.3804C106.007 59.3958 106.223 59.4267 106.409 59.4731L106.177 61.3514C105.991 61.3051 105.783 61.2664 105.551 61.2355C105.334 61.2046 105.126 61.1891 104.925 61.1891C104.476 61.1891 104.051 61.2896 103.649 61.4906C103.247 61.6916 102.884 61.9776 102.559 62.3486C102.25 62.7042 102.003 63.137 101.817 63.6472C101.632 64.1573 101.539 64.7293 101.539 65.3632V71.9953H99.5215V59.5659H101.168L101.4 61.8384H101.493C101.74 61.3746 102.034 60.9572 102.374 60.5862C102.729 60.1997 103.131 59.8983 103.58 59.6818C104.028 59.4499 104.523 59.334 105.064 59.334Z"
          fill="white"
        />
        <path
          d="M90.0555 59.3594C91.4777 59.3594 92.5367 59.6918 93.2324 60.3565C93.9435 61.0213 94.2991 62.0802 94.2991 63.5334V71.9975H92.8382L92.4439 70.2351H92.3512C92.0111 70.6835 91.6632 71.0622 91.3077 71.3714C90.9676 71.6651 90.5656 71.8816 90.1018 72.0207C89.6535 72.1598 89.097 72.2294 88.4322 72.2294C87.7365 72.2294 87.1104 72.098 86.5539 71.8352C85.9973 71.5724 85.5567 71.1704 85.2321 70.6294C84.9074 70.0728 84.7451 69.3771 84.7451 68.5423C84.7451 67.3056 85.2012 66.3548 86.1133 65.69C87.0254 65.0098 88.4322 64.6388 90.3337 64.577L92.3048 64.5074V63.7653C92.3048 62.7295 92.0961 62.0107 91.6787 61.6087C91.2768 61.2068 90.697 61.0058 89.9395 61.0058C89.3366 61.0058 88.7569 61.1063 88.2003 61.3073C87.6592 61.4928 87.1413 61.7092 86.6466 61.9566L86.0205 60.4261C86.5461 60.1324 87.1568 59.885 87.8525 59.684C88.5482 59.4676 89.2825 59.3594 90.0555 59.3594ZM90.612 65.9915C89.1897 66.0533 88.2003 66.3007 87.6438 66.7336C87.1027 67.1664 86.8322 67.7771 86.8322 68.5655C86.8322 69.2612 87.0254 69.7713 87.4119 70.096C87.7984 70.4206 88.3008 70.583 88.9192 70.583C89.8777 70.583 90.6816 70.297 91.3309 69.725C91.9802 69.1375 92.3048 68.2409 92.3048 67.035V65.9219L90.612 65.9915Z"
          fill="white"
        />
        <path
          d="M71.2153 71.9966L66.2065 57.4801H66.1137C66.1446 57.7893 66.1678 58.1835 66.1833 58.6628C66.2142 59.142 66.2374 59.6676 66.2528 60.2396C66.2683 60.7962 66.276 61.3682 66.276 61.9556V71.9966H64.3745V55.4395H67.3891L72.0965 69.0052H72.1893L76.9895 55.4395H80.0041V71.9966H77.9634V61.8165C77.9634 61.2754 77.9711 60.7421 77.9866 60.2164C78.0021 59.6754 78.0253 59.1729 78.0562 58.7091C78.0871 58.2299 78.1103 57.8279 78.1257 57.5033H78.033L72.9313 71.9966H71.2153Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
