import { useCallback, useEffect, useRef, useState, ReactNode, FC } from 'react';
import { message, Spin } from '@myua/kit';
import * as api from '../../api';
import { isValid, OTPInput } from './OTPInput';
import './OTPEnroll.scss';
import { OTPTimer } from './OTPTimer';
import { ReceiveSquare, Scan, Unlock } from '@myua/icons/dist/bulk';

export const OTPEnrollInner: FC<{
  onSuccess: () => void;
  open: boolean;
  onClose: () => void;
}> = ({ onSuccess, open, onClose }) => {
  // state
  const [qr, setQR] = useState<string | null>(null);
  const [qrLoading, setQRLoading] = useState(false);
  const [confirmCode, setConfirmCode] = useState<string | null>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  //ref
  const input = useRef<{ focus: () => void; focusOnDigit: (n: number) => void }>(null);
  const abortRef = useRef(new AbortController());

  //callback
  const confirm = useCallback(() => {
    void (async () => {
      if (confirmCode === null) return;
      setConfirmLoading(true);
      const result = await api.OTPConfirm(confirmCode, abortRef.current.signal);
      setConfirmLoading(false);
      if (abortRef.current.signal.aborted) {
        return;
      }

      if (result.success) {
        onSuccess();
      } else {
        setConfirmCode(null);
        input.current?.focus();
        void message.error('Try again');
      }
    })();
  }, [confirmCode, onSuccess]);

  useEffect(() => {
    if (isValid(confirmCode)) {
      confirm();
    }
  }, [confirmCode, confirm]);

  useEffect(() => {
    if (open) {
      input.current?.focus();
    }
  }, [open]);

  // effect
  useEffect(() => {
    void (async () => {
      abortRef.current = new AbortController();
      setQRLoading(true);
      const result = await api.OTPEnable(abortRef.current.signal);
      setQRLoading(false);

      if (abortRef.current.signal.aborted) {
        return;
      }

      if (result.success) {
        setQR(result.data.qrcode);
        input.current?.focus();
      } else {
        onClose();
        onSuccess();
        return;
      }
    })();
    return () => {
      abortRef.current.abort();
      setQRLoading(false);
      setConfirmLoading(false);
      setQR(null);
      setConfirmCode(null);
    };
  }, [open, onSuccess, onClose]);

  return (
    <div className="otp-enroll__container">
      <div className="otp-enroll">
        <div className="otp-enroll__left">
          <StepInfo
            icon={<ReceiveSquare />}
            title="Download the authenticator app"
            description={
              <>
                Download and install the Google Authenticator or similar app on your mobile phone (
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  rel="noreferrer"
                >
                  for Android
                </a>
                ,{' '}
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                >
                  for IOS
                </a>
                )
              </>
            }
          />
          <StepInfo
            icon={<Scan />}
            title="Scan the QR-code"
            description="Open the app, tap on + and scan the code"
          />
          <StepInfo
            icon={<Unlock />}
            title="Enter with the safety code"
            description="Fill in the code from your app to the fields under the QR-code to login to AdsAdvisor"
          />
        </div>
        <div className="otp-enroll__right">
          <div className="otp-enroll__qr">
            {qrLoading ? <Spin /> : qr ? <img src={qr} alt="qr code" /> : null}
            <OTPTimer />
          </div>
          <div className="otp-enroll__confirm">
            <div>Code entry field</div>
            <OTPInput
              _ref={input}
              disabled={confirmLoading}
              length={6}
              value={confirmCode ?? ''}
              onChange={setConfirmCode}
              focus
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StepInfo: FC<{ icon: ReactNode; title: ReactNode; description: ReactNode }> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="otp-enroll-step-info">
      <div className="otp-enroll-step-info__icon">{icon}</div>
      <div className="otp-enroll-step-info__main">
        <div className="otp-enroll-step-info__title">{title}</div>
        <div>{description}</div>
      </div>
    </div>
  );
};
